import './App.css'
import './output.css'
import Header from './components/Header'
import Ingress from './components/Ingress';
import MintInfo from './components/MintInfo';
import Collection from './components/Collection';
import Holder from './components/Holder';
import Footer from './components/Footer';

function App() {
  return (
    <div className="bg-[#37343c] text-white">
      <Header />
      <MintInfo />
      <Ingress />
      <Holder />
      <Collection />
      <Footer />
    </div>
  );
}

export default App;
