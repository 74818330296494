import bookburner from '../img/collection/1.png';
import bostonteaparty from '../img/collection/2.png';
import varangian from '../img/collection/14.png';
import goon from '../img/collection/4.png';
import wall from '../img/collection/5.png';
import gunpowder from '../img/collection/6.png';
import hms from '../img/collection/7.png';
import pressure from '../img/collection/8.png';
import revolver from '../img/collection/9.png';
import maiden from '../img/collection/10.png';
import teutonic from '../img/collection/11.png';
import trebuche from '../img/collection/12.png';
import troika from '../img/collection/13.png';

const images = [
{
    image: hms,
    rarity: "Unique",
},
{
    image: wall,
    rarity: "Unique",
},
{
    image: varangian,
    rarity: "Epic",
},
{
    image: trebuche,
    rarity: "Epic",
},
{
    image: pressure,
    rarity: "Epic",
},
{
    image: revolver,
    rarity: "Rare",
},
{
    image: bostonteaparty,
    rarity: "Rare",
},
{
    image: teutonic,
    rarity: "Rare",
},
{
    image: troika,
    rarity: "Rare",
},
{
    image: bookburner,
    rarity: "Common",
},
{
    image: goon,
    rarity: "Common",
},
{
    image: gunpowder,
    rarity: "Common",
},
{
    image: maiden,
    rarity: "Common",
},
]

const rarityToText = {
    "Rare": "750 mints",
    "Common": "1400 mints",
    "Epic": "400 mints",
    "Unique": "100 mints",
}

const getImage = (i) => {
    const meta = images[i];

    return <div className="flex flex-col justify-center items-center text-[26px]">
        <img className="h-[300px]" src={meta.image}></img>
        <div>{meta.rarity}</div>
        <div>{rarityToText[meta.rarity]}</div>
    </div>
}

const CardGallery = () => {
    return (
        <div>
            <div className="flex justify-center items-center gap-2 flex-wrap flex-col md:flex-row">
                <div>{getImage(0)}</div>
                <div>{getImage(1)}</div>
            </div>
            <div className="flex justify-center items-center gap-8 pt-8 flex-col md:flex-row">
                <div>{getImage(2)}</div>
                <div>{getImage(3)}</div>
                <div>{getImage(4)}</div>
            </div>
            <div className="hidden lg:flex justify-center items-center gap-8 pt-8 flex-col md:flex-row">
                <div>{getImage(5)}</div>
                <div>{getImage(6)}</div>
                <div>{getImage(7)}</div>
                <div>{getImage(8)}</div>
            </div>
            <div className="flex lg:hidden justify-center items-center gap-2 lg:gap-8 flex-wrap flex-col md:flex-row">
                <div>{getImage(5)}</div>
                <div>{getImage(6)}</div>
            </div>
            <div className="flex lg:hidden justify-center items-center gap-2 lg:gap-8 flex-wrap flex-col md:flex-row">
                <div>{getImage(7)}</div>
                <div>{getImage(8)}</div>
            </div>
            <div className="hidden lg:flex justify-center items-center gap-8 pt-8 flex-col md:flex-row">
                <div>{getImage(9)}</div>
                <div>{getImage(10)}</div>
                <div>{getImage(11)}</div>
                <div>{getImage(12)}</div>
            </div>
            <div className="flex lg:hidden justify-center items-center gap-2 lg:gap-8 flex-wrap flex-col md:flex-row">
                <div>{getImage(9)}</div>
                <div>{getImage(10)}</div>
            </div>
            <div className="flex lg:hidden justify-center items-center gap-2 lg:gap-8 flex-wrap flex-col md:flex-row">
                <div>{getImage(11)}</div>
                <div>{getImage(12)}</div>
            </div>
            
            
        </div>
    );
}

export default CardGallery;