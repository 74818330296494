import TwitterLogo from '../img/twitter.svg'
import DiscordLogo from '../img/discord.svg'
import Immutablelogo from '../img/immutablelogo.svg'

import Logo from '../img/towlogosmall.png';
const Footer = () => {
    return (
        <div className="text-[#e7d2bf] bg-[#312214] flex items-center justify-center flex-col">
            <img className="mt-[40px] h-40" src={Logo}></img>
            <span className="font-sans text-[#c4ac88] mt-[40px] max-w-[800px] text-center ml-[10px] mr-[10px]">
                Titans of War is a strategic AAA card game based on Historical events developed by Pro players and experts in the TCG field and backed by Immutable.
                Titans of War will be the first and biggest Esports game on the blockchain, with revenue from battle passes and boosters going to prize pools powering the World Championships.
            </span>

            <div id="contacts" className="min-h-[200px] z-10 w-full flex flex-wrap items-center justify-center gap-16 pt-8 pb-8 text-[#bc9d7e]">
                <div className="w-11">
                    <a href="https://twitter.com/TitansofWar_TCG" target="_blank"><img src={TwitterLogo}></img></a>
                </div>
                <div className="w-11">
                    <a href="https://discord.com/invite/ax3ykcKWGf" target="_blank"><img src={DiscordLogo}></img></a>
                </div>
                <div className="text-[40px]">
                    <a className='hover:underline' href="http://medium.com/@titansofwar" target="_blank">Blog</a>
                </div>
                <div className="text-[40px]">
                    <a className='hover:underline' href="https://titansofwar.com" target="_blank">Website</a>
                </div>
                <div className="w-[150px]">
                    <a href="http://www.immutable.com" target="_blank"><img src={Immutablelogo}></img></a>
                </div>



            </div>
        </div>
    );
}

export default Footer;