import CheckMark from '../img/checkmark.png'
import NotCompleted from '../img/notcompleted.svg'

const CompleteTask = (completed) => {
    return (
        <div>
            <img className="h-9" src={completed.props.completed ? CheckMark : NotCompleted}></img>
        </div>
    )
}

export default CompleteTask;