import Logo from '../img/towlogosmall.png';
import Countdown from 'react-countdown';

const utcDate = new Date(Date.UTC(2023, 3, 21, 15, 0, 0, 0));
const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
};
const mintDate = new Intl.DateTimeFormat("sv-SE", options).format(utcDate)
const Ingress = () => {
    return (
        <div className="text-[#e7d2bf] main-grad flex items-center flex-col pb-10">
            <img className="mt-4 h-48 sm:h-80" src={Logo}></img>
            <div className="text-[36px] sm:text-[48px] flex justify-center items-center flex-col w-full">
                <div className="hidden sm:block">Witness history with the Titans</div>
                <div className="hidden sm:block">of War Genesis NFT card collection</div>
                <div className="sm:hidden text-center">Witness history with the Titans of War 'Origin' NFT card collection</div>
            </div>
            <div className="text-[16px] sm:text-[20px] text-[#c4ac88] font-sans max-w-[800px] flex justify-center items-center flex-col gap-4">
                <div>Are you ready for history?</div>
                <div className="text-center">Welcome to the first ever Titans of War NFT Card Collection, marking the birth of the iconic history themed web3 Trading Card game backed by Immutable.</div>
                <div className="text-center">We are proud to present the ‘Origin’ set of Titans of War NFT Cards that will be playable with game launch , and has 13 designs each with evolving art through a unique burn mechanism.</div>
            </div>
            <button className="mt-12 w-[350px] h-[60px] font-sans text-[20px] text-[#000000] font-bold rounded-full bg-[#e89262]" >Whitelist mint ongoing!
            </button>
            <button className="mt-12 w-[450px] h-[60px] font-sans text-[20px] text-[#000000] font-bold rounded-full bg-[#e89262]" >
                Public mint countdown: <Countdown date={utcDate} />
            </button>

        </div>
    );
}

export default Ingress;