import rex from '../img/rex.png';
import knight from '../img/knight.png';

const Holder = () => {
    return (
         <div  className="text-[#e7d2bf] flex items-center flex-col pb-10 pt-10 bg-[#312214]">
            <div className="text-[36px] sm:text-[48px] flex justify-center items-center flex-col w-full pb-10 text-center">
                <div className="hidden lg:block">Titans of War 'Origin' NFT Card Collection</div>
                <div className="hidden lg:block">Holder Benefits</div>
                <div className="lg:hidden">Titans of War 'Origin' NFT Card Collection Holder Benefits</div>
            </div>
            <div className="flex justify-center items-center flex-col md:flex-row">
                <div className="flex-[1] font-sans max-w-[900px]">
                    <div className="ml-10 mr-10 border-l pl-[20px] border-[#ce5f14]">
                        <div className=" text-white">SPECIAL NFTs for COMPLETE COLLECTIONS</div>
                        Holders of all 13 cards from the Titans of War NFT Card Collection will get a special NFT as a reward. These NFTs will have no colour or region (in game terms), and never be made available moving forward. Moreover they will have Unique rarity (the highest rarity in the game). Only holders of all 13 cards from this first mint will get these NFTs.
                        <div className="text-[16px] pt-10 italic font-thin">Note: We will take a snapshot on a specific day to collect the wallet addresses of those who hold all 13.</div>
                    </div>
                    <div className="ml-10 mr-10 border-l pl-[20px] border-[#ce5f14] mt-10">
                        <div className=" text-white">$TITANS AIRDROP ENTRY!</div>
                        At the time of the $TITANS airdrop, a snapshot will be taken and holders of our NFT Card Collection will be entered into the airdrop by default, tiered as per the quantity and quality of the NFT Cards collected. The more you hold the higher the airdrop! 
                        <div className="text-[16px] pt-10 italic font-thin">Note: The exact details of the $TITANS airdrop will be shared when relevant.</div>
                    </div>
                    
                        <div className="ml-10 mr-10 border-l pl-[20px] border-[#ce5f14] mt-10">
                            <div className=" text-white">EARLY BETA ACCESS!</div>
                            Holders of the First Titans of War NFT Card Collection will get early beta access to the game and be the first in the public to do so.
                        </div>
                </div>
                <div className="flex-[1]  pl-[10px] pr-[10px] sm:pr-0 sm:pl-20 md:pt-0 pt-8">
                    <img className="max-h-[600px]" src={rex}></img>
                </div>
            </div>


            <div className="flex justify-center items-center pt-20 flex-col-reverse md:flex-row">
                <div className="flex-[1] pl-[10px] pr-[10ox] sm:pl-0 sm:pr-20">
                    <img className="max-h-[600px]" src={knight}></img>
                </div>
                <div className="flex-[1] font-sans max-w-[900px]">
                    <div className="ml-10 md:ml-0 mr-10 border-l pl-[20px] border-[#ce5f14]">
                        <div className=" text-white">WHITELIST FOR NEXT MINT</div>
                        As Day 1 supporters of the Titans of War story, holders will automatically get whitelist spots in the next mint when it takes place.
                    </div>
                    <div className="ml-10 md:ml-0 mr-10 border-l pl-[20px] border-[#ce5f14] mt-10">
                        <div className=" text-white">BURN NFTs for SPECIAL CARDS at 50% DISCOUNT</div>
                        Burning 2 cards of the same kind will turn them into a FULL ART version of the same card. Further, burning 2 full art cards will turn it into an ANIMATED FULL ART card! 
                        <div className="text-[16px] pt-10 italic font-thin">Note: Once the game is released, players will be able to use this same burn mechanism but it will take burning 4 cards of the same kind to make a full art card, and 4 full art cards burnt to make an animated card. So holders doing this now are getting a 50% DISCOUNT on burn rewards!</div>
                    </div>
                    <div className="ml-10 md:ml-0 mr-10 border-l pl-[20px] border-[#ce5f14] mt-10">
                        <div className=" text-white">MERCH DISCOUNT!</div>
                        15% discount on all Titans of War merch (when launched).
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Holder;