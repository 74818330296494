import { useEffect, useState } from 'react';
import whitelogo from '../img/logowhite.png';
import { useMetaMask } from "metamask-react";

function Header() {
  const { status, connect, account, chainId, ethereum } = useMetaMask();
  return (
    <div className="sticky top-0 h-20 z-20 flex justify-between pl-10 pr-10 header-grad opacity-90">
      <a href="https://www.titansofwar.com" target="_blank"><img className="p-2 h-20" src={whitelogo}></img></a>
      { account != null && account.length ? <div className="text-[24px] flex items-center justify-center">{account.substring(0, 5)}...{account.substring(account.length - 4, account.length)}</div>
      : <button className="text-[20px] border rounded-xl border-[#c4ac88] text-[#c4ac88] bg-[#2b1707] m-[10px] p-[10px]" onClick={connect}>Connect Wallet</button>}
    </div> 
  );
}

export default Header;
