import CardGallery from "./CardGallery";

const utcDate = new Date(Date.UTC(2023, 3, 20, 15, 0, 0, 0));
const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
};
const mintDate = new Intl.DateTimeFormat("sv-SE", options).format(utcDate)
const Collection = () => {
    return (
        <div className="text-[#e7d2bf] join-grad flex items-center flex-col pt-8 pb-40 bg-[#3e2d1f]">
            <div className="text-[36px] sm:text-[48px] flex justify-center items-center flex-col w-full">
                <div>Explore the Collection</div>
                <div className="text-[24px] sm:text-[36px] text-[#e89262] ml-4 mr-4 text-center">13 Evolving NFT Cards playable in Titans of War</div>
            </div>
            <div className="pt-20">
                <CardGallery />
            </div>
            <div className="text-[#e7d2bf] flex flex-col items-center pt-28">
                <div className="text-[36px] sm:text-[48px]">
                    <div>Come join history!</div>
                </div>
                <div className="flex flex-col sm:flex-row gap-8 pt-8">
                    <div className="border-[3px] border-[#fe9746] rounded-lg w-[180px] md:w-[200px] h-[100px] flex flex-col justify-center items-center">
                        <span>Total NFTs Available</span>
                        <span className="text-[26px] md:text-[30px]">10 000</span>
                    </div>
                    <div className="border-[3px] border-[#fe9746] rounded-lg w-[180px] md:w-[200px] h-[100px] flex flex-col justify-center items-center">
                        <span>Mint Type</span>
                        <span className="text-[26px] md:text-[30px]">Random Mint</span>
                    </div>
                    <div className="border-[3px] border-[#fe9746] rounded-lg w-[180px] md:w-[200px] h-[100px] flex flex-col justify-center items-center leading-none text-center">
                        <span>Mint Date</span>
                        <span className="text-[26px] md:text-[30px]">{mintDate}</span>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-start max-w-[800px] rounded-2xl bg-[#29060630] p-[20px] mt-[80px] ml-[10px] mr-[10px]">
                    <span className="text-[36px]">Whitelist mint</span>
                    <span className="font-sans text-[#c4ac88] pt-8">Price: FREE</span>
                    <span className="font-sans text-[#c4ac88] pt-4">
                        The Titans of War NFT Card Collection Whitelist is for collaborations with communities, thought leaders and the community members of Titans of War partners such as Immutable.
                        Whitelist will be DTC (direct to contract), which means that our collaboration partners collect the addresses and we add them to our whitelist.
                    </span>
                </div>

                <div className="flex flex-col justify-center items-start max-w-[800px] rounded-2xl bg-[#29060630] p-[20px] mt-[80px] ml-[10px] mr-[10px]">
                    <span className="text-[36px]">Public sale mint</span>
                    <span className="font-sans text-[#c4ac88] pt-8">Price: FREE</span>
                    <span className="font-sans text-[#c4ac88] pt-4">
                        The public sale starts 24 hours after the whitelist mint begins, and everyone can mint as many NFTs as they like (max 1 per transaction).
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Collection;