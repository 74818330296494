import { Config, ImmutableX } from "@imtbl/core-sdk";
import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { ImmutableXClient, Link, TransferV2ResultsCodec, ERC721TokenType, NFT_ASSET_ID_PREFIX } from '@imtbl/imx-sdk'
import CompleteTask from "./CompleteTask";
import RotatingArrow from '../img/rotatingarrow.svg'

const API_BASE_URL = 'https://mintapi.titansofwar.com/api'
const MARKET_URL = "https://market.immutable.com/";
const CONTRACT = "0x29fcb1e910f0dafa1c44b1cea9e336483f2d3ba4";
const showDetailedInfo = false;
const upgradeEnabled = false;

const HeroPanel = () => {
    //const ETH_MAINNET_ADDRESS_RIKARD = "0x686f24Ad45DE8Abf8C08E78e587f152bECFfb198"
    //const ETH_MAINNET_ADDRESS_MATS = "0x558547c522A0725A1bcAc21c884E7E9ae17DfFe6"
    const { status, connect, account, chainId, ethereum } = useMetaMask();
    const [error, setError] = useState("");
    const [hasIMXAccount, setHasIMXAccount] = useState(false)
    const [correctChain, setCorrectChain] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [isWhiteListed, setIsWhitelisted] = useState(false)
    const [canMint, setCanMint] = useState(false)
    const [alreadyMinted, setAlreadyMinted] = useState(false)
    const [assets, setAssets] = useState({})

    const [minting, setMinting] = useState(false);

    // 1st february
    const mintDate = new Date(Date.UTC(2023, 3, 20, 15, 0, 0, 0));
    const mintTimePassed = (new Date()).getTime() > mintDate.getTime();



    useEffect(() => {
        console.log("We changed the chainId to:" + chainId);
        if (chainId === "0x1") {
            setCorrectChain(true)
        } else {
            setCorrectChain(false)
        }
    }, [chainId])

    useEffect(() => {
        if (status === "connected") {
            setIsConnected(true)
        } else {
            setIsConnected(false)
        }
    }, [status])

    useEffect(() => {
        console.log("We changed the account to:" + account);
    }, [account])

    useEffect(() => {
        ; (async () => {
            var hasAcc = await getUser(account)
            console.log("User does have IMX? " + hasAcc)
            setHasIMXAccount(hasAcc);
        })()

            ; (async () => {
                if (account?.length > 0) {
                    var response = await fetch(`${API_BASE_URL}/check-whitelist/${account}`)
                    const jsonResp = await response.json()
                    setIsWhitelisted(jsonResp.whitelisted)
                    setCanMint(jsonResp.canMint)
                    setAlreadyMinted(jsonResp.alreadyMinted)
                    console.log("check-whitelist response: ", jsonResp)
                }
            })()

    }, [account, alreadyMinted])

    const mintNFT = async (account) => {
        setMinting(true);
        console.log("Lets call our API to mint an NFT to account: " + account);
        const response = await fetch(`${API_BASE_URL}/perform-mint/${account}`)
        console.log(await response.json())
        console.log("Minting finished");
        if (response.ok) {
            setAlreadyMinted(true);
        }
        setMinting(false);
    }

    const popupLink = async () => {
        let link = new Link('https://link.x.immutable.com')
        try {
            let result = await link.setup({})
            console.log(result)
            setHasIMXAccount(true);
        } catch (error) {
            console.error(error)
        }
    }

    const changeChain = async () => {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
        });
    }

    useEffect(() => {
        console.log("FetchAssets: starting");
        const fetchAssets = async (eth_address) => {
            if (hasIMXAccount && eth_address) {
                let assetCursor
                let allAssets = []
                do {
                    let assetRequest = await getIMXClient().getAssets({ user: eth_address, collection: CONTRACT, cursor: assetCursor })
                    allAssets = allAssets.concat(assetRequest.result)
                    assetCursor = assetRequest.cursor
                } while (assetCursor)
                let stackedAssets = await stackAssets(allAssets);
                console.log(stackedAssets);
                setAssets(stackedAssets);
            }
        }
        fetchAssets(account);
    }, [account, hasIMXAccount, alreadyMinted]);


    return (
        <div>
            <div className="bg-[#2c2c2c] flex flex-col pl-8 lg:pl-0 lg:items-center justify-center  min-h-[300px] font-sans pt-8 pb-8">
                <ul className="flex flex-col lg:flex-row justify-between gap-4 lg:gap-8 text-[18px]">
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: isConnected }} />
                        {isConnected ? "Metamask wallet connected!" : <a className="underline cursor-pointer" onClick={connect}>Connect metamask wallet</a>}
                    </li>
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: correctChain }} />
                        {correctChain ? "Ethereum mainnet selected" : <a className="underline cursor-pointer" onClick={changeChain}>Switch chain to Ethereum mainnet</a>}
                    </li>
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: hasIMXAccount }} />
                        {hasIMXAccount ? "Have IMX Account" : (correctChain ? <a className="underline cursor-pointer" onClick={popupLink}>Create IMX Account</a> : "Create IMX Account")}
                    </li>
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: isWhiteListed }} />Get whitelisted</li>
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: mintTimePassed }} />Wait for mint date</li>
                    <li className="flex lg:flex-col  gap-4 items-center"><CompleteTask props={{ completed: alreadyMinted }} />
                        {alreadyMinted ? "NFT Minted!" : "Mint your NFT!"}
                    </li>
                </ul>
                <div className="relative mt-8">
                    <button className={`rounded-lg  h-24 w-48 text-[20px] border-4 m-3 border-white cursor-default`}>
                        <div className="flex justify-evenly">
                            MINTED OUT!
                        </div>
                    </button>
                </div>
            </div>
            {Object.keys(assets).length > 0 &&
                <div className="bg-[#2c2c2c]">
                    <div className="pt-10 flex justify-center">
                        <header>Your Titans of War NFTs</header>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        {getDivsForStacks(assets, account)}
                    </div>
                </div>
            }
        </div >
    )

}

const fetchNFTs = async (account) => {
    let client = new ImmutableX(Config.PRODUCTION);
    let burnedNFTs = client.listTransfers({
        tokenAddress: CONTRACT,
        status: "success",
        receiver: "0x0000000000000000000000000000000000000000",
        user: account
    })
    console.log(burnedNFTs);
}

const stackAssets = async (unstackedAssets) => {
    let stackedAssets = {};
    for (const i in unstackedAssets) {
        let asset = unstackedAssets[i];
        let key;
        if (showDetailedInfo) {
            key = asset.token_id.substring(asset.token_id.length - 9);
        } else {
            key = asset.token_id;
        }
        if (asset.metadata == null) {
            console.log("IMX doesn't have metadata, fetch from our servers instead");
            try {
                var response = await fetch("https://api.titansofwar.com/cards/card/" + asset.token_id);
                asset.metadata = await response.json();
                asset.image_url = asset.metadata.image_url;
            } catch (e) {
                continue;
            }
        }
        if (!stackedAssets[key]) {
            stackedAssets[key] = [asset];
        } else {
            stackedAssets[key].push(asset);
        }
    }
    return stackedAssets;
}

const getDivsForStacks = (stacks, account) => {
    var renderedNFTs = Object.keys(stacks).sort().map(stackKey => {
        let stack = stacks[stackKey];
        return (
            <div className="text-center  bg-[#37343c] m-2" key={stackKey}>
                Count: {stack.length}
                <a className="hover:underline" href={MARKET_URL + "/inventory/assets/" + CONTRACT + "/" + stack[0].token_id} target="_blank" rel="noreferrer">
                    <img src={stack[0].image_url} className="w-[339px] h-[475px]" />
                </a>
                {showDetailedInfo && <span><a className="hover:underline" href={MARKET_URL + "/collections/" + CONTRACT + "?filters[card_id][]=" + stackKey.substring(4)} target="_blank" rel="noreferrer">Buy more!</a><span> - </span></span>}<a className="hover:underline" href={MARKET_URL + "/inventory/assets/" + CONTRACT + "/" + stack[0].token_id} target="_blank" rel="noreferrer">Sell</a>
                {stack.length >= 2 && upgradeEnabled && <p><a className="hover:underline cursor-pointer" onClick={() => burn(stack, account)}>Upgrade!</a></p>}
            </div>)
    })
    return renderedNFTs;
}


const burn = async (stack, account) => {
    console.log("burn " + stack);
    let link = new Link('https://link.x.immutable.com')

    const transferResponsePayload = await link.transfer([
        {
            type: ERC721TokenType.ERC721,
            tokenId: stack[0].token_id,
            tokenAddress: '0xfb81D5a4d9231FAAE8f6F7918e41eE766155320B',
            toAddress: '0x0000000000000000000000000000000000000000',
        },
        {
            type: ERC721TokenType.ERC721,
            tokenId: stack[1].token_id,
            tokenAddress: '0xfb81D5a4d9231FAAE8f6F7918e41eE766155320B',
            toAddress: '0x0000000000000000000000000000000000000000',
        },
    ]);
    console.log(transferResponsePayload);
    if (transferResponsePayload.result[0].status === 'success' && transferResponsePayload.result[1].status === 'success') {
        const response = await fetch(`${API_BASE_URL}/upgrade/${account}?first=${stack[0].token_id}&second=${stack[1].token_id}`);
    }

}

const getUser = async (eth_address) => {
    const client = new ImmutableX(Config.PRODUCTION);
    try {
        var a = await client.getUser(eth_address)
        console.log(a)
        return true;
    } catch (e) {
        return false
    }
}

const getIMXClient = () => {
    return new ImmutableXClient("https://api.x.immutable.com/v1")
}


export default HeroPanel;